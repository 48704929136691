import styled from "styled-components";

export const CaseStudyCardWrapper = styled.div`
    margin-top: 20px;

    &:not(:last-child) {
        margin-bottom: 50px;
    }

    h6 {
        margin: 20px 0 10px 0;
        font-size: 0.875rem;
        font-weight: 400;
        font-family: 'Barlow Semi Condensed';

        @media screen and (min-width: 1000px) {
            font-size: 1rem;
    }
    }
`