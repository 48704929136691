import * as React from "react"
import styled from "styled-components"

import Layout from "../../components/theme/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import CaseStudyCardComponent from "../../components/case-study-components/case-study-card/case-study-card.component"

import './index.scss'

const CaseStudyPage = () => (
    <Layout>
        <Seo title="Qiwio Case Studies" />
        <PageWrapper>
            <h2>Don't just take our word for it, check out these case studies of proven success with Qiwio</h2>

            <CaseStudyWrapper>
                {/* Images are not working
                <CaseStudyCardComponent
                    link='/'
                    img='../../images/customers/aj produkter/Office 16.6.jpeg'
                    imgAlt='AJ Produkter Office'
                    customerName='AJ Produkter'
                    title='AJ Produkter increased their conversions by a staggering 47% after just 2 months of using Qiwio.'
                />
                */}

                <Link to='/case-studies/aj-produkter'>
                    <CaseStudyCard className="case-study-card">
                        <StaticImage
                            className="case-study-img"
                            style={{
                                gridArea: '1/1'
                            }}
                            src='../../images/customers/aj produkter/Office 16.6.jpeg'
                            alt='AJ Produkter Office'
                            loading='eager'
                            placeholder='none'
                        />
                        <CaseStudyCardOverlay>
                            <h3>AJ Produkter created interactive 'shoppable' product guides for a smoother digital shopping experience</h3>
                            <p>AJ Produkter</p>
                        </CaseStudyCardOverlay>
                    </CaseStudyCard>
                 </Link>


                <Link to='/case-studies/tenson'>
                    <CaseStudyCard className="case-study-card">
                            <StaticImage
                                className="case-study-img"
                                style={{
                                    gridArea: '1/1'
                                }}
                                src='../../images/customers/tenson/yokun-rain-jacket-w.jpg'
                                alt='Yokun Rain Jacket W'
                                loading='eager'
                                placeholder='none'
                            />
                            <CaseStudyCardOverlay>
                                <h3>Tenson achieved a 70% engagement rate with interactive shopping</h3>
                                <p>Tenson</p>
                            </CaseStudyCardOverlay>
                    </CaseStudyCard>
                </Link>
            </CaseStudyWrapper>
        </PageWrapper>
    </Layout>
)

const PageWrapper = styled.div`
  padding: 50px 20px;

  @media screen and (min-width: 900px) {
    padding: 100px 50px;
  }

  h2 {
    font-weight: 600;

    @media screen and (min-width: 1000px) {
        margin-bottom: 30px;
        max-width: 40vw;
    }
  }
`
const CaseStudyWrapper = styled.div`
    @media screen and (min-width: 1000px) {
        display: grid;
        grid-template-columns: repeat(2, 49.5%);
        column-gap: 1vw;
    }
`
const CaseStudyCard = styled.div`
    margin-top: 20px;
    display: grid;
    border-radius: 10px;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 50px;
    }

    h6 {
        margin: 20px 0 10px 0;
        font-size: 0.875rem;
        font-weight: 400;
        font-family: 'Barlow Semi Condensed';

        @media screen and (min-width: 1000px) {
            font-size: 1rem;
    }
    }
`
const CaseStudyCardOverlay = styled.div`
    grid-area: 1/1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 20px;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 30%, rgba(0,0,0,0) 100%);

    h3 {
        font-size: 1.25rem;
        margin-bottom: 5px;
        font-weight: 600;
    }
    p {
        font-size: 0.875rem;
        margin-bottom: 20px;
    }
`
export default CaseStudyPage

